import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "How Flexbox Order Works",
  "date": "2017-08-21",
  "promo": "flexbox",
  "description": "The flexbox order property isn't busted, you just need to know the right way to think about it.",
  "color": "#f1eea0"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Flexbox is fantastic but it does have a few quirks that are not very intuitive. One thing that's sure to trip up new flexboxers is the `}<inlineCode parentName="p">{`order`}</inlineCode>{` property. It doesn't behave how you'd think. The story usually goes something like this: you've got a flex item that you'd like to make sure shows up `}<em parentName="p">{`first`}</em>{`, so you write this code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.make-it-first-please {
  order: 1;
}
`}</code></pre>
    <p>{`You refresh the page - feeling smug about your CSS prowess - only to see that item jump to the `}<em parentName="p">{`end`}</em>{` of the row rather than to the `}<em parentName="p">{`beginning`}</em>{` like you were expecting. `}<em parentName="p">{`Oh snap flexbox is broken!`}</em></p>
    <h2>{`Positive Order`}</h2>
    <p>{`Don't feel too bad, at least you're not alone. I asked my followers on `}<a parentName="p" {...{
        "href": "//twitter.com/geddski"
      }}>{`twitter`}</a>{` to take a guess without cheating, and the majority got it wrong:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/polls/order1.png",
        "alt": "flex positive order"
      }}></img></p>
    <p>{`The correct answer is B: the item actually `}<strong parentName="p">{`moves to the back`}</strong>{` (without swapping places).`}</p>
    <p>{`The key to making sense of flexbox `}<inlineCode parentName="p">{`order`}</inlineCode>{` is to think of it like this:`}</p>
    <div className="formula">
  Flex items render in ascending order (smallest to largest), defaulting to zero
  when not specified.
    </div>
    <p>{`It's like while you were taking a Diet Coke break someone snuck this into the top of your stylesheet:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`* {
  order: 0;
}
`}</code></pre>
    <p>{`But now it makes sense! Giving an item `}<inlineCode parentName="p">{`order: 1`}</inlineCode>{` makes it come `}<em parentName="p">{`after`}</em>{` items with the default `}<inlineCode parentName="p">{`order: 0`}</inlineCode>{`.`}</p>
    <p>{`Here's an example, notice the `}<inlineCode parentName="p">{`.blue`}</inlineCode>{` with `}<inlineCode parentName="p">{`order: 1`}</inlineCode>{` has jumped to the end of the row, even though it was the second item in the DOM.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
  }\n
  .item {
    height: 200px;
    width: 200px;
  }\n
  .blue{ 
    order: 1;
  }   
`} html={`
  <div class="container">
    <div class="item red"></div>
    <div class="item blue"></div>
    <div class="item green"></div>
    <div class="item grey"></div>
    <div class="item purple"></div>  
  </div>   
`} mdxType="CodeExample" />
    <h2>{`Negative Order`}</h2>
    <p>{`Alright, so what about a `}<em parentName="p">{`negative`}</em>{` flex order? Is that allowed? I asked a similar question to my `}<span className="strike">{` lab rats `}</span>{` loyal followers:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/polls/order2.png",
        "alt": "flex positive order"
      }}></img></p>
    <p>{`The correct answer here is B: the item `}<strong parentName="p">{`moves to the front`}</strong>{`. This behavior seems wonky until you remember that items default order is zero, so an item with a negative order would definitely render first.`}</p>
    <p>{`Here's an example. The `}<inlineCode parentName="p">{`.purple`}</inlineCode>{` item has `}<inlineCode parentName="p">{`order: -1`}</inlineCode>{` so it jumps to the `}<em parentName="p">{`front`}</em>{` of the line:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
  }\n
  .item {
    height: 200px;
    width: 200px;
  }\n
  .purple{ 
    order: -1; 
  }   
`} html={`
  <div class="container">
    <div class="item red"></div>
    <div class="item blue"></div>
    <div class="item green"></div>
    <div class="item grey"></div>
    <div class="item purple"></div>  
  </div>   
`} mdxType="CodeExample" />
    <p>{`And that's the trick to remember:`}</p>
    <div className="formula">
  order defaults to zero, renders in ascending order.
    </div>
    <p>{`Makes sense now, doesn't it! Take `}<em parentName="p">{`that`}</em>{` you crafty spec writers!`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`Seeing this rule and internalizing it are two different things. Get some practice with flexbox order by shooting the zombies in `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com/courses/139425/lectures/2788345"
      }}>{`Flexbox Zombies chapter 8: Out of Order`}</a>{`. They're trying to be sneaky - switching their flexbox orders all around. Go show 'em who's boss.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      